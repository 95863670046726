import React, { createContext, useState, useContext } from 'react';
import { UserProfileResponse } from '../libs/fetchUserProfile/fetchUserProfile';

interface UserProfileContextType {
  userProfile: any;
  setUserProfile: (userProfile: any) => void;
}

// Create a context to store the userProfile (array of numbers)
const UserProfileContext = createContext<UserProfileContextType>(undefined);

export function UserProfileWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [userProfile, setUserProfile] = useState<UserProfileResponse>();

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
}

export function useUserProfileContext() {
  return useContext(UserProfileContext);
}

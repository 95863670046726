import GlobalHeader from '@bt-react/components/GlobalHeader';
import { useUserProfileContext } from '../../context/UserProfileContext';
import React, { FC } from 'react';

interface HeaderContainerProps {
  env: string;
  headerSearchBar: boolean;
  hostUrl: string;
}

const HeaderContainer: FC<HeaderContainerProps> = ({
  env,
  headerSearchBar,
  hostUrl,
}) => {
  const { userProfile } = useUserProfileContext();
  return (
    <GlobalHeader
      environment={env}
      searchBar={headerSearchBar}
      joinBtn={true}
      hostUrl={hostUrl}
      isLoggedIn={userProfile?.isLoggedIn ? true : false}
    />
  );
};

export default HeaderContainer;
